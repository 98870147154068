import {
  EnumsGamePackLeaderboardRule,
  EnumsGamePackMakeUnitsFrom,
} from '@lp-lib/api-service-client/public';
import { EnumsGamePackInstructionRule } from '@lp-lib/api-service-client/public';
import { EnumsGamePackMakeup } from '@lp-lib/api-service-client/public';

import { type Option } from '../../common/Utilities';
import { type GPv2SettingsTemplate } from './types';

const GPV2_SETTING_TEMPLATES: GPv2SettingsTemplate[] = [
  {
    id: 'social-game',
    name: 'Social Game',
    similarTo: 'Drawing a Blank, Apples to Apples',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupOneBigGame,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack,
      defaultUnitsPerSession: 1,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfEachNewBrand,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEndOfSession,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: false,
    },
    replayable: true,
  },
  {
    id: 'social-game-no-pts',
    name: 'Social Game - No Points',
    similarTo: 'Hum That Tune',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupOneBigGame,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack,
      defaultUnitsPerSession: 1,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfSession,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleNeverShow,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: false,
    },
    replayable: true,
  },
  {
    id: 'narrative',
    name: 'Narrative',
    similarTo: 'Wes Anderson',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupOneBigGame,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack,
      defaultUnitsPerSession: 1,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleNeverShow,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleNeverShow,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: false,
    },
    replayable: false,
  },
  {
    id: 'escape-room',
    name: 'Escape Room',
    similarTo: 'Escape the Park',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupOneBigGame,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack,
      defaultUnitsPerSession: 1,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfSession,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEndOfSession,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: false,
    },
    replayable: false,
  },
  {
    id: 'combo',
    name: 'Combo',
    similarTo: 'Trivia',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupMultipleRounds,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromConsecutiveBrandBlocks,
      defaultUnitsPerSession: 5,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfEachNewBrand,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEveryGameBrandChange,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: false,
    },
    replayable: false,
  },
  {
    id: 'program-arcade',
    name: 'Programmed Arcade',
    similarTo: 'Over Roasted/Team Relay',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupMultipleLevels,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromIndividualBlocks,
      defaultUnitsPerSession: 3,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfEachNewBrand,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEndOfSession,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: true,
    },
    replayable: true,
  },
  {
    id: 'single-brand',
    name: 'Single Brand',
    similarTo: 'Password/Eat Your Words',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupMultipleRounds,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromIndividualBlocks,
      defaultUnitsPerSession: 3,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfSession,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEndOfSession,
      lockUnitsPerSession: true,
      randomizeUnitOrder: true,
      resumeFromLastUnitPlayed: true,
    },
    replayable: true,
  },
  {
    id: 'dei',
    name: 'DEI',
    similarTo: 'DEI Content',
    playbackSettings: {
      gameMakeup: EnumsGamePackMakeup.GamePackMakeupOneBigGame,
      makeUnitsFrom:
        EnumsGamePackMakeUnitsFrom.GamePackMakeUnitsFromWholeGamePack,
      defaultUnitsPerSession: 1,
      instructionRules:
        EnumsGamePackInstructionRule.GamePackInstructionRuleStartOfEachNewBrand,
      leaderboardRules:
        EnumsGamePackLeaderboardRule.GamePackLeaderboardRuleEveryGameBrandChange,
      lockUnitsPerSession: true,
      randomizeUnitOrder: false,
      resumeFromLastUnitPlayed: false,
    },
    replayable: false,
  },
];

export class GPv2TemplateUtils {
  static readonly templates = GPV2_SETTING_TEMPLATES;
  static MakeSelectOptions() {
    return this.templates.map<Option<string>>((template) => ({
      label: `${template.name} (${template.similarTo})`,
      value: template.id,
    }));
  }

  static GetTemplate(
    id: string | undefined
  ): Readonly<GPv2SettingsTemplate | undefined> {
    return this.templates.find((t) => t.id === id);
  }

  static EnsureNoDuplication() {
    const set = new Set<string>();
    this.templates.forEach((t, i) => {
      if (set.has(t.id)) {
        throw new Error(`Duplicate template id: ${t.id} at index ${i}`);
      }
      set.add(t.id);
    });
  }
}

GPv2TemplateUtils.EnsureNoDuplication();
