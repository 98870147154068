import { Link } from '@remix-run/react';
import { useMemo } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import useSWR from 'swr';

import { type ModelsGamePackPrice } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { uuidv4 } from '../../utils/common';
import { type GamePackEditorFormData } from '../Game/GamePack/types';
import { DeleteIcon } from '../icons/DeleteIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { SwitcherControlled } from '../Switcher';

function useDefaultPricingTable() {
  const { data } = useSWR(`/settings/one-time-purchase/data`, async () => {
    const resp = await apiService.settings.getData('one-time-purchase');
    return resp.data.data.pricingTable;
  });
  return data ?? [];
}

export function OneTimePurchasePriceEditor(_props: {
  packId: string | null | undefined;
}) {
  const useDefaultPrices = useWatch<
    GamePackEditorFormData,
    'extraSettings.usingDefaultPricingTable'
  >({ name: 'extraSettings.usingDefaultPricingTable' });

  const defaultPricesToggle = useMemo(() => {
    return <UseDefaultPricesToggle />;
  }, []);

  return useDefaultPrices ? (
    <DefaultOneTimePurchasePriceView
      defaultPricesToggle={defaultPricesToggle}
    />
  ) : (
    <CustomOneTimePurchasePriceEditor
      defaultPricesToggle={defaultPricesToggle}
    />
  );
}

function DefaultOneTimePurchasePriceView(props: {
  defaultPricesToggle: React.ReactNode;
}) {
  const defaultPrices = useDefaultPricingTable();

  const sortedPrices = useMemo(() => {
    const pricesWithIndex = defaultPrices.map((price, index) => ({
      price,
      index,
    }));
    return pricesWithIndex.sort((a, b) => {
      if (a.price.archived && !b.price.archived) return 1;
      if (!a.price.archived && b.price.archived) return -1;
      return a.price.maxPlayers - b.price.maxPlayers;
    });
  }, [defaultPrices]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between gap-4'>
        {props.defaultPricesToggle}
        <Link
          to='/admin/purchases/pricing-table'
          className='text-icon-gray text-xs underline'
          target='_blank'
        >
          Manage default prices
        </Link>
      </div>
      {sortedPrices.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No prices
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse'>
          <thead>
            <tr className='text-white'>
              <th className='text-left py-2 border-b border-secondary w-[40%]'>
                Max Players
              </th>
              <th className='text-left py-2 border-b border-secondary'>
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPrices.map(({ price }) => (
              <tr
                key={price.id}
                className={`hover:bg-dark-gray ${
                  price.archived ? 'opacity-60' : 'opacity-100'
                }`}
              >
                <td className='text-left text-icon-gray py-3 pl-3 pr-6 border-b border-black-001'>
                  <span>{price.maxPlayers}</span>
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                  <div className='flex items-center gap-2 pr-6'>
                    <span className='font-bold'>$</span>
                    <span>{price.amount}</span>
                    {price.archived && (
                      <div className='ml-auto flex flex-col items-center justify-center'>
                        <div className='text-2xs text-icon-gray uppercase tracking-wide font-bold'>
                          Archived
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

function CustomOneTimePurchasePriceEditor(props: {
  defaultPricesToggle: React.ReactNode;
}) {
  const { control, register, formState } =
    useFormContext<GamePackEditorFormData>();
  const { errors } = formState;
  const error = errors.extraSettings?.oneTimePurchasePricingTable?.root;

  const {
    fields: customPrices,
    append,
    remove,
    update,
  } = useFieldArray<
    GamePackEditorFormData,
    'extraSettings.oneTimePurchasePricingTable',
    'key'
  >({
    control,
    name: 'extraSettings.oneTimePurchasePricingTable',
    keyName: 'key',
    rules: {
      validate: {
        validatePrices: (values) => {
          return (
            values.every(
              (value) => value.maxPlayers >= 0 && value.amount > 0
            ) || 'Invalid price table, Max Players >= 0 and Price > 0'
          );
        },
      },
    },
  });
  const newPriceIdSet = useInstance(() => new Set<string>());

  const addPriceEntry = (price: ModelsGamePackPrice) => {
    append(price);
    newPriceIdSet.add(price.id);
    // NOTE(jialin): this code makes the type hint super slow. We will handle
    // it in the backend.
    // react-hook-form v8 seems can solve this.
    // https://github.com/react-hook-form/react-hook-form/issues/6679#issuecomment-1135203812
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // setValue('free', false);
  };

  const handleAddPrice = () => {
    const maxPlayers =
      Math.max(0, Math.max(...customPrices.map((p) => p.maxPlayers))) + 50;
    addPriceEntry({ id: uuidv4(), maxPlayers, amount: 0, archived: false });
  };

  const handleDeletePrice = (
    index: number | undefined,
    price: ModelsGamePackPrice
  ) => {
    if (index === undefined) return;
    if (newPriceIdSet.has(price.id)) {
      remove(index);
    } else {
      update(index, { ...price, archived: true });
    }
  };

  const handleUnarchivePrice = (
    index: number | undefined,
    price: ModelsGamePackPrice
  ) => {
    if (index === undefined) return;
    update(index, { ...price, archived: false });
  };

  const sortedPrices = useMemo(() => {
    const pricesWithIndex = customPrices.map((price, index) => ({
      price,
      index,
    }));
    return pricesWithIndex.sort((a, b) => {
      if (a.price.archived && !b.price.archived) return 1;
      if (!a.price.archived && b.price.archived) return -1;
      return a.price.maxPlayers - b.price.maxPlayers;
    });
  }, [customPrices]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex items-center justify-between gap-4'>
        {props.defaultPricesToggle}
        <button
          type='button'
          className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
          onClick={handleAddPrice}
        >
          <PlusIcon />
          Add Price
        </button>
      </div>
      {sortedPrices.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No prices
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse'>
          <thead>
            <tr className={`${error ? 'text-red-002' : 'text-white'}`}>
              <th className='text-left py-2 border-b border-secondary w-[40%]'>
                Max Players
              </th>
              <th className='text-left py-2 border-b border-secondary'>
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPrices.map(({ price, index }) => (
              <tr
                key={price.id}
                className={`hover:bg-dark-gray ${
                  price.archived ? 'opacity-60' : 'opacity-100'
                }`}
              >
                <td className='text-left text-icon-gray py-3 pl-3 pr-6 border-b border-black-001'>
                  <input
                    type='number'
                    className='w-full h-10 field mb-0'
                    autoComplete='off'
                    {...register(
                      `extraSettings.oneTimePurchasePricingTable.${index}.maxPlayers`,
                      {
                        valueAsNumber: true,
                      }
                    )}
                    disabled={price.archived}
                  />
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                  <div className='flex items-center gap-2 pr-6'>
                    <span className='font-bold'>$</span>

                    <input
                      type='number'
                      className='w-1/2 h-10 field mb-0'
                      autoComplete='off'
                      key={price.id}
                      {...register(
                        `extraSettings.oneTimePurchasePricingTable.${index}.amount`,
                        {
                          valueAsNumber: true,
                        }
                      )}
                      disabled={price.archived}
                    />
                    {price.archived && (
                      <div className='ml-auto flex flex-col items-center justify-center'>
                        <div className='text-2xs text-icon-gray uppercase tracking-wide font-bold'>
                          Archived
                        </div>
                        <button
                          type='button'
                          className='btn text-primary underline text-3xs'
                          onClick={() => handleUnarchivePrice(index, price)}
                        >
                          Undo
                        </button>
                      </div>
                    )}
                    {!price.archived && (
                      <button
                        type='button'
                        className='ml-auto text-red-001'
                        onClick={() => handleDeletePrice(index, price)}
                      >
                        <DeleteIcon />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {error && <div className='text-sms text-red-002'>{error.message}</div>}
    </div>
  );
}

function UseDefaultPricesToggle() {
  return (
    <Controller<
      GamePackEditorFormData,
      'extraSettings.usingDefaultPricingTable'
    >
      name='extraSettings.usingDefaultPricingTable'
      render={({ field }) => (
        <label className='flex items-center justify-center gap-2 text-sm text-primary'>
          <div className='cursor-pointer'>Use default prices</div>
          <SwitcherControlled
            name={field.name}
            checked={field.value || false}
            onChange={(checked) => field.onChange(checked)}
          />
        </label>
      )}
    />
  );
}
